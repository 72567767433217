import { createHermes } from '@saghen/hermes';
import { AuthHermesRuntimeMessage, HermesRuntimeMessage } from 'interfaces';

import { User } from 'lib/speechify/auth';
import { createEventEmitter } from 'lib/speechify/EventEmitter';
import { logError } from 'utils/errorLogging';

import { isOnMicrosoftEdgeBrowser } from './browser';
import { isServerSide } from './environment';
import { getOptionalStringEnv } from './safeEnvParsing';

export type ExtensionVoice = {
  displayName: string;
  localizedDisplayName: Record<string, string>;
  name: string;
  language: string;
  engine: string;
  gender: 'male' | 'female';
  labels?: string[];
  tags?: string[];
  personal?: boolean;
  avatarImage?: string;
  deprecated?: boolean;
  previewAudio?: string;
  premium: boolean;
};

type ExtensionMessageFunctions = 'setVoiceSpeed';

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtensionMessage<TPayload = any> = {
  domain: 'com.speechify.extension';
  fnName: ExtensionMessageFunctions;
  payload: TPayload;
};

type SetVoiceSpeedMessage = ExtensionMessage<{ voice: ExtensionVoice; history?: ExtensionVoice[]; playbackSpeed: number }>;

const CHROME_EXTENSION_ID = 'ljflmlehinmoeknoonhibbjpldiijjmm';
const EDGE_ADDON_ID = 'ogapibpahpkbcdidopigillpmndjemnj';

function isExtensionMessage(data: $TSFixMe | ExtensionMessage): data is ExtensionMessage {
  return (data as ExtensionMessage).domain === 'com.speechify.extension';
}

function isSetVoiceSpeedMessage(data: $TSFixMe | SetVoiceSpeedMessage): data is SetVoiceSpeedMessage {
  return isExtensionMessage(data) && (data as SetVoiceSpeedMessage).fnName === 'setVoiceSpeed';
}

export const ExtensionMessageEmitter = createEventEmitter<{
  setVoice: { voice: ExtensionVoice };
  setHistory: { history: ExtensionVoice[] };
  setSpeed: { speed: number };
}>({
  events: ['setVoice', 'setHistory', 'setSpeed']
});

// TODO: create "router" setup for postMessages to handle this
const listenToPost = (event: MessageEvent) => {
  const { data } = event;
  if (event.source === window && isSetVoiceSpeedMessage(data)) {
    const { voice } = data.payload;
    ExtensionMessageEmitter.emit('setVoice', { voice });

    if (data.payload.playbackSpeed) ExtensionMessageEmitter.emit('setSpeed', { speed: data.payload.playbackSpeed });

    if (data.payload.history) {
      ExtensionMessageEmitter.emit('setHistory', { history: data.payload.history });
    }
  }
};

export const initMessageListener = () => {
  // window check to get around next.js SSR
  if (typeof window !== 'undefined') {
    window.addEventListener('message', listenToPost);
  }

  return () => window.removeEventListener('message', listenToPost);
};

const initializeHermes = (extensionId: string) => {
  return createHermes({
    transport: {
      fetch: (path, request) => {
        // ESLint: Unexpected any
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const { chrome } = window as any;
        return new Promise(resolve => chrome.runtime.sendMessage(extensionId, { hash: '', path, request, isHermes: true }, resolve));
      }
    }
  });
};

const _checkIfExtensionIdIsInstalled = async (
  extensionId: string
): Promise<
  | {
      installed: boolean;
      hermesInstance?: ReturnType<typeof createHermes>;
    }
  | {
      installed: false;
    }
> => {
  const hermesInstance = initializeHermes(extensionId);
  if (typeof window === 'undefined') {
    return {
      installed: false
    };
  }
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { chrome } = window as any;

  if (!chrome || !chrome.runtime) {
    return {
      installed: false
    };
  }

  try {
    const respose = await hermesInstance.fetch<
      {
        version?: string;
      },
      // ESLint: Don't use `{}` as a type
      // eslint-disable-next-line @typescript-eslint/ban-types
      {}
    >('/version', { body: {}, meta: {} });
    if (respose.body?.version) {
      return {
        installed: true,
        hermesInstance
      };
    }
    return {
      installed: false
    };
  } catch (e) {
    logError(e);
    return {
      installed: false
    };
  }
};

export const checkIfEdgeExtensionIsInstalled = async () => {
  const { installed } = await _checkIfExtensionIdIsInstalled(EDGE_ADDON_ID);
  return Boolean(installed);
};

export const checkIfChromeExtensionIsInstalled = async () => {
  const { installed } = await _checkIfExtensionIdIsInstalled(CHROME_EXTENSION_ID);
  return Boolean(installed);
};

const installedExtensionPromise: Promise<{
  hermesInstance: ReturnType<typeof createHermes>;
  installed: boolean;
  extensionId: string;
  // ESLint: 'reject' is defined but never used & Promise executor functions should not be async
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-async-promise-executor
}> = new Promise(async (resolve, reject) => {
  if (isServerSide()) {
    resolve({
      hermesInstance: initializeHermes(CHROME_EXTENSION_ID),
      installed: false,
      extensionId: CHROME_EXTENSION_ID
    });
    return;
  }
  // Preference: extensionId query param > Chrome Extension ID > Edge Extension ID
  // Steps:
  // 1. Create Hermes instance with fetch that sends message to extension, if fail, try next ID
  // 2. If success, set extensionId to that ID
  const extensionIdParam = getParams().get('extensionId');
  if (extensionIdParam) {
    const extensionFromParam = await _checkIfExtensionIdIsInstalled(extensionIdParam);
    if (extensionFromParam.installed) {
      resolve({
        // @ts-expect-error TS(2322): Type '{ fetch: <T, K>(path: string, request?: IReq... Remove this comment to see the full error message
        hermesInstance: extensionFromParam.hermesInstance,
        installed: true,
        extensionId: extensionIdParam
      });
      return;
    }
  }
  const productionChromeExtension = await _checkIfExtensionIdIsInstalled(CHROME_EXTENSION_ID);
  if (productionChromeExtension.installed) {
    resolve({
      // @ts-expect-error TS(2322): Type '{ fetch: <T, K>(path: string, request?: IReq... Remove this comment to see the full error message
      hermesInstance: productionChromeExtension.hermesInstance,
      installed: true,
      extensionId: CHROME_EXTENSION_ID
    });
    return;
  }
  const productionEdgeAddOns = await _checkIfExtensionIdIsInstalled(EDGE_ADDON_ID);
  if (productionEdgeAddOns.installed) {
    resolve({
      // @ts-expect-error TS(2322): Type '{ fetch: <T, K>(path: string, request?: IReq... Remove this comment to see the full error message
      hermesInstance: productionEdgeAddOns.hermesInstance,
      installed: true,
      extensionId: EDGE_ADDON_ID
    });
    return;
  }
  resolve({
    hermesInstance: initializeHermes(CHROME_EXTENSION_ID),
    installed: false,
    extensionId: CHROME_EXTENSION_ID
  });
});

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function sendMessage<T, U = HermesRuntimeMessage | AuthHermesRuntimeMessage>(path: string, request: U): Promise<any> {
  // ESLint: Unexpected any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { chrome } = window as any;
  if (!chrome || !chrome.runtime) return Promise.reject({ message: 'Chrome runtime not available' });
  const { hermesInstance } = await installedExtensionPromise;
  // ESLint: Don't use `{}` as a type
  // eslint-disable-next-line @typescript-eslint/ban-types
  return hermesInstance.fetch<T, {}>(path, { body: request, meta: {} });
}

export async function getExtensionId() {
  const { extensionId } = await installedExtensionPromise;
  return extensionId;
}

export async function checkExtensionInstalled(): Promise<boolean> {
  const { installed } = await installedExtensionPromise;
  return installed;
}

export async function enableGamification() {
  return sendMessage('/gamification/enable', {}).catch(e => {
    logMessageError(e);
  });
}

export async function disableGamification() {
  return sendMessage('/gamification/disable', {}).catch(e => logMessageError(e));
}

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getGamificationState(): Promise<any | undefined> {
  return sendMessage<string>('/gamification/get-cached-state', {})
    .then(response => response.body)
    .catch(e => {
      logMessageError(e);
    });
}

export async function getIdToken(): Promise<string | undefined> {
  return sendMessage<string>('/auth/get-id-token', {})
    .then(response => response.body?.idToken)
    .catch(e => {
      logMessageError(e);
    });
}

function getParams() {
  return isServerSide() ? new URLSearchParams() : new URL(document.location.href).searchParams;
}

export async function getReadableTabs() {
  return sendMessage('/readability/get-readable-tabs', {}).catch(e => {
    logMessageError(e);
  });
}

// ESLint: Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getSettings(): Promise<any | undefined> {
  return sendMessage<string>('/user-settings/get', {})
    .then(response => {
      const settings = response.body;

      if (parseFloat(settings?.installVersion) < 7.5) {
        if (settings.voice.displayName === 'Matthew') {
          settings.voice.displayName = 'Matthew';
        }
      }

      return settings;
    })
    .catch(e => {
      logMessageError(e);
    });
}

export async function getUser(): Promise<User | void> {
  return sendMessage<string>('/auth/get-user', {})
    .then(response => response.body?.user)
    .catch(e => {
      logMessageError(e);
    });
}

export async function handleAuth(provider: string, value: $TSFixMe) {
  return sendMessage('/auth/handle-auth', { provider, value }).catch(e => {
    logMessageError(e);
  });
}

export function isBrowserSupported(): boolean {
  // @ts-expect-error Property 'chrome' does not exist on type 'Window & typeof globalThis'
  return !!window.chrome;
}

export async function logOut() {
  return sendMessage('/auth/log-out', {}).catch(e => {
    logMessageError(e);
  });
}

export async function logWordsConsumed(activity: $TSFixMe) {
  return sendMessage('/auth/update-words-consumed', {
    areHDWords: activity.options.voice.isPremium,
    wordsConsumed: activity.wordsListened
  }).catch(e => {
    logMessageError(e);
  });
}

export async function setPlaybackSpeed(playbackSpeed: number) {
  return sendMessage('/user-settings/set-playback-speed', { playbackSpeed, isChangeFromWebApp: true }).catch(e => {
    logMessageError(e);
  });
}

export async function setStreakGoal(streakGoalInDays: number) {
  return sendMessage('/gamification/set-streak-goal', { streakGoalInDays }).catch(e => {
    logMessageError(e);
  });
}

export async function setVoice(voice: { displayName?: string; name?: string; engine?: string }) {
  let displayName = voice.displayName;
  const settings = await getSettings();

  if (parseFloat(settings?.installVersion ?? 0) < 7.5) {
    if (voice.displayName === 'Matthew') {
      displayName = 'Matthew';
    }
  }

  sendMessage('/user-settings/set-voice', { voice: { ...voice, displayName }, isChangeFromWebApp: true }).catch(e => {
    logMessageError(e);
  });
}

export async function saveReadableTabs(tabUrls: string[], shouldCloseTabs: boolean) {
  return sendMessage('/readability/save-tabs', {
    tabUrls,
    shouldCloseTabs
  }).catch(e => {
    logMessageError(e);
  });
}

export function getUserVoiceSelectionHistory(): Promise<ExtensionVoice[]> {
  return sendMessage('/user-settings/get-voice-selection-history', {})
    .then(response => {
      return response.body;
    })
    .catch(e => {
      logMessageError(e);
      throw e;
    });
}

export function removeSavedItem(itemId: string) {
  return sendMessage('/library/remove', { itemId }).catch(e => {
    logMessageError(e);
  });
}

export function saveImportedItem({ itemId, sourceURL, title }: { itemId: string; sourceURL: string; title: string }) {
  return sendMessage('/library/save-from-web-app', { itemId, sourceURL, title }).catch(e => {
    logMessageError(e);
  });
}

function logMessageError(e: $TSFixMe) {
  const error = e.error;
  !getOptionalStringEnv('NEXT_PUBLIC_DEV_DISABLE_NOISY_CONSOLE_LOG') && error && logError(error);
}

export const CHROME_STORE_URL = 'https://chromewebstore.google.com/detail/speechify-for-chrome/ljflmlehinmoeknoonhibbjpldiijjmm';
export const EDGE_STORE_URL = 'https://microsoftedge.microsoft.com/addons/detail/ogapibpahpkbcdidopigillpmndjemnj';

const isEdgeExtensionId = (extensionId?: string) => extensionId === EDGE_ADDON_ID;
const isChromeExtensionId = (extensionId?: string) => extensionId === CHROME_EXTENSION_ID;

export enum ExtensionType {
  CHROME = 'CHROME',
  EDGE = 'EDGE'
}

const CHROME_EXTENSION_DATA = {
  extensionStoreUrl: CHROME_STORE_URL,
  extensionType: ExtensionType.CHROME
};

const EDGE_EXTENSION_DATA = {
  extensionStoreUrl: EDGE_STORE_URL,
  extensionType: ExtensionType.EDGE
};

export type RecommendedExtensionData = {
  extensionStoreUrl: string;
  extensionType: ExtensionType;
};

export const getRecommendedExtensionToInstall = (): RecommendedExtensionData => {
  try {
    // When `?extensionId` is defined to the production Chrome/Edge ID, redirect accordingly to the respective store URL
    const searchParams = getParams();
    const extensionId = searchParams.get('extensionId');
    // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    if (isChromeExtensionId(extensionId)) {
      return CHROME_EXTENSION_DATA;
      // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    } else if (isEdgeExtensionId(extensionId)) {
      return EDGE_EXTENSION_DATA;
    }

    // If the user is on Edge, recommend the Edge extension
    if (isOnMicrosoftEdgeBrowser()) {
      return EDGE_EXTENSION_DATA;
    }
    return CHROME_EXTENSION_DATA;
  } catch (e) {
    logError(e as Error);
    return CHROME_EXTENSION_DATA;
  }
};
