import { MetaAttributes, PushErrorOptions } from '@grafana/faro-core';
import { PushEventOptions } from '@grafana/faro-core/dist/types/api/events/types';
import { type MeasurementsAPI, PushMeasurementOptions } from '@grafana/faro-core/dist/types/api/measurements/types';
import { Faro, LogsAPI, MetaUser } from '@grafana/faro-web-sdk';
import { noop } from 'lodash';

import { ErrorWithContext } from 'utils/error';

import { getOptionalStringEnv, getRequiredStringEnv } from '../../utils/safeEnvParsing';
import { ConsoleInstrumentation } from './ConsoleInstrumentation';
import { isGrafanaCloudFeatureFlagEnabled } from './featureFlag';
import { DUMMY_SESSION_ID, initFaro } from './initFaro';
import { prepareData } from './prepare-data';

let faroSpeechify: Faro | undefined;
let faroCloud: Faro | undefined;

let resolveFaroInitPromise: () => void = noop;
const faroInitPromise = new Promise<void>(resolve => {
  resolveFaroInitPromise = resolve;
});

(async () => {
  if (typeof window !== 'undefined' && window.location.hostname !== 'localhost') {
    const faroCollectorUrl = getOptionalStringEnv('NEXT_PUBLIC_FARO_COLLECTOR_URL');
    if (faroCollectorUrl) {
      faroSpeechify = await initFaro(faroCollectorUrl, { isGrafanaSpeechifyHosted: true });
    }

    const isCloudFaroEnabled = await isGrafanaCloudFeatureFlagEnabled();

    if (isCloudFaroEnabled) {
      const faroCloudCollectorUrl = getOptionalStringEnv('NEXT_PUBLIC_FARO_CLOUD_COLLECTOR_URL');
      if (faroCloudCollectorUrl) {
        faroCloud = await initFaro(faroCloudCollectorUrl, { isolate: true });
      }
    }
  }
  resolveFaroInitPromise();
})();

const log = async (...args: Parameters<LogsAPI['pushLog']>) => {
  await faroInitPromise;
  faroSpeechify?.api.pushLog(...args);
  faroCloud?.api.pushLog(...args);
};

const logFaroError = async (faro: Faro | undefined, error: Error, source: string | undefined = undefined, options: PushErrorOptions = { context: {} }) => {
  await faroInitPromise;
  if (faro) {
    let errorToLog = error;

    if (errorToLog && errorToLog.message === 'Chrome runtime not available') {
      return;
    }

    if (!(error instanceof Error)) {
      errorToLog = new Error(error);
    }

    let context = source ? { ...options.context, source } : options.context;
    if (error instanceof ErrorWithContext) {
      context = { ...context, ...error.context };
    }

    faro.api.pushError(errorToLog, {
      ...(options || {}),
      context
    });
    const consoleInstrumentation = faro.instrumentations.instrumentations.find(i => i instanceof ConsoleInstrumentation);

    if (consoleInstrumentation) {
      if (consoleInstrumentation.unpatchedConsole) {
        try {
          if (source) {
            consoleInstrumentation.unpatchedConsole.error(errorToLog, source);
          } else {
            consoleInstrumentation.unpatchedConsole.error(errorToLog);
          }
        } catch (e) {
          console.error('Error logging failed through instrumentation, falling back to default console.error', errorToLog, source);
        }
      }
    }
  } else if (source) {
    console.error(error, source);
  } else {
    console.error(error);
  }
};

const logError = async (error: unknown, source: string | undefined = undefined, options: PushErrorOptions = { context: {} }) => {
  await faroInitPromise;
  if (error instanceof Error) {
    logFaroError(faroSpeechify, error, source, options);
    logFaroError(faroCloud, error, source, options);
  } else {
    const newError = new Error(`Non-error value was thrown somewhere: ${JSON.stringify(error)}`);
    logError(newError);
  }
};

const logEventInObservability = async (name: string, attributes?: MetaAttributes, domain?: string, options?: PushEventOptions) => {
  await faroInitPromise;
  faroSpeechify?.api.pushEvent(name, prepareData(attributes), domain, options);
  faroCloud?.api.pushEvent(name, prepareData(attributes), domain, options);
};

const logMeasurement = async (...args: Parameters<MeasurementsAPI['pushMeasurement']>) => {
  await faroInitPromise;
  (getRequiredStringEnv('NODE_ENV') === 'development' || getOptionalStringEnv('NEXT_PUBLIC_VERBOSE_MEASUREMENT')) &&
    (() => {
      const output = [args[0]['type'], args[0]['values'], args[1] && args[1].context].filter(Boolean);
      console.info('[measure] ', ...output);
    })();
  faroSpeechify?.api.pushMeasurement(...args);
  faroCloud?.api.pushMeasurement(...args);
};

const measure = async (name: string, time: number, options: PushMeasurementOptions = {}) => {
  await faroInitPromise;
  logMeasurement(
    {
      type: name,
      values: {
        [name]: time
      }
    },
    options
  );
};

const createMeasurement = async (name: string) => {
  await faroInitPromise;
  const startTime = performance.now();

  return () => {
    const endTime = performance.now() - startTime;
    logMeasurement({
      type: name,
      values: {
        [name]: endTime
      }
    });
  };
};

// ESLint: Unexpected any & Unexpected any
// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-explicit-any
const instrumentAction = async <F extends (...args: any[]) => any>(cb: F, name: string): Promise<ReturnType<F>> => {
  await faroInitPromise;
  try {
    const end = await createMeasurement(name);
    const result = await cb();
    end();
    return result;
  } catch (e) {
    if (e instanceof Error) {
      faroSpeechify?.api.pushError(e, { type: name });
      faroCloud?.api.pushError(e, { type: name });
    }

    throw e;
  }
};

const updateFaroSessionAttributes = async (faro: Faro | undefined, attributes: MetaAttributes) => {
  await faroInitPromise;
  if (faro) {
    const session = faro.api.getSession() || {};

    faro.api.setSession({
      id: DUMMY_SESSION_ID,
      ...session,
      attributes: {
        ...(session?.attributes || {}),
        ...prepareData(attributes),
        previousSession: DUMMY_SESSION_ID
      }
    });

    faro.api.pushLog(['Faro session updated']);
  }
};

const updateSessionAttributes = async (attributes: MetaAttributes) => {
  await faroInitPromise;
  updateFaroSessionAttributes(faroSpeechify, attributes);
  updateFaroSessionAttributes(faroCloud, attributes);
};

const setUser = async (user: MetaUser) => {
  await faroInitPromise;
  faroSpeechify?.api.setUser(user);
  faroCloud?.api.setUser(user);
};

export { createMeasurement, instrumentAction, log, logError, logEventInObservability, logMeasurement, measure, setUser, updateSessionAttributes };
