import React from 'react';

export function OpenInNewTabIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3333 4L5.66666 4.00007C5.29847 4.00007 5 4.29855 5 4.66674L5.00003 15.1821C5.00003 15.503 5.21562 15.742 5.4726 15.7899C8.49597 16.3531 11.5041 16.3531 14.5274 15.7899C14.7844 15.742 15 15.503 15 15.1821V4.66667C15 4.29848 14.7015 4 14.3333 4ZM5.66665 2.00007L14.3333 2C15.8061 1.99999 17 3.1939 17 4.66667V15.1821C17 16.4062 16.1555 17.521 14.8937 17.756C11.6282 18.3643 8.37179 18.3643 5.10633 17.756C3.84448 17.521 3.00003 16.4062 3.00003 15.1821L3 4.66674C3 3.19399 4.19389 2.00008 5.66665 2.00007ZM6.5 6.99999C6.5 6.4477 6.94771 5.99999 7.5 5.99999H12.5C13.0523 5.99999 13.5 6.4477 13.5 6.99999C13.5 7.55227 13.0523 7.99999 12.5 7.99999H7.5C6.94771 7.99999 6.5 7.55227 6.5 6.99999ZM7.5 9.99999C6.94771 9.99999 6.5 10.4477 6.5 11C6.5 11.5523 6.94771 12 7.5 12H12.5C13.0523 12 13.5 11.5523 13.5 11C13.5 10.4477 13.0523 9.99999 12.5 9.99999H7.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ExportIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path d="M2.25 9.75L9 3M9 3H3.75M9 3V8.25" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export function RenameIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7979 5.02803L15.0622 4.29238C14.868 4.09822 14.5536 4.09697 14.3579 4.28958L13.1019 5.52579L14.5403 6.96424L15.7938 5.73916C15.9929 5.54458 15.9947 5.22487 15.7979 5.02803ZM4.16587 14.3206L11.6764 6.92874L13.1099 8.36225L5.45572 15.8431C5.3623 15.9344 5.23686 15.9856 5.10623 15.9856L4.0166 15.9856V14.677C4.0166 14.543 4.07038 14.4146 4.16587 14.3206ZM16.4764 2.87817C15.5056 1.90735 13.9335 1.90109 12.955 2.86415L2.76296 12.8952C2.28549 13.3651 2.0166 14.007 2.0166 14.677V17.1522C2.0166 17.6125 2.3897 17.9856 2.84994 17.9856L5.10623 17.9856C5.75936 17.9856 6.38656 17.73 6.85365 17.2735L17.1917 7.16947C18.1871 6.19658 18.1963 4.59804 17.2121 3.61381L16.4764 2.87817ZM10.5003 16C9.94797 16 9.50026 16.4477 9.50026 17C9.50026 17.5523 9.94797 18 10.5003 18H17.0003C17.5525 18 18.0003 17.5523 18.0003 17C18.0003 16.4477 17.5525 16 17.0003 16H10.5003Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function MoveIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 17C2.89543 17 2 16.1046 2 15V4C2 2.89543 2.89543 2 4 2H7.30465C7.94297 2 8.54295 2.30469 8.91953 2.8201L9.77866 3.99596L16 3.99596C17.1046 3.99596 18 4.89139 18 5.99596V10C18 10.5523 17.5523 11 17 11C16.4477 11 16 10.5523 16 10L16 5.99596L9.27082 5.99596C8.95166 5.99596 8.65167 5.84361 8.46338 5.58591L7.30465 4L4 4L4 15H9C9.55229 15 10 15.4477 10 16C10 16.5523 9.55228 17 9 17H4ZM15 17V18C15 18.4045 15.2436 18.7691 15.6173 18.9239C15.991 19.0787 16.4211 18.9931 16.7071 18.7071L18.7071 16.7071C19.0976 16.3166 19.0976 15.6834 18.7071 15.2929L16.7071 13.2929C16.4211 13.0069 15.991 12.9213 15.6173 13.0761C15.2436 13.2309 15 13.5955 15 14V15H12C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H15Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function ShareIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.36378 2.03033C9.71525 1.67886 10.2851 1.67886 10.6366 2.03033L14.6366 6.03033C14.988 6.3818 14.988 6.95165 14.6366 7.30312C14.2851 7.65459 13.7153 7.65459 13.3638 7.30312L10.9002 4.83952V13.259C10.9002 13.756 10.4972 14.159 10.0002 14.159C9.50312 14.159 9.10018 13.756 9.10018 13.259V4.83952L6.63657 7.30312C6.2851 7.65459 5.71525 7.65459 5.36378 7.30312C5.01231 6.95165 5.01231 6.3818 5.36378 6.03033L9.36378 2.03033Z"
        fill="currentColor"
      />
      <path
        d="M17.5669 13C17.5669 12.5029 17.164 12.1 16.6669 12.1C16.1699 12.1 15.7669 12.5029 15.7669 13V15.201C15.7669 15.5665 15.518 15.8568 15.1997 15.9178C11.6133 16.6051 8.38722 16.6051 4.80085 15.9178C4.48251 15.8568 4.23359 15.5665 4.23359 15.201L4.23359 13C4.23359 12.5029 3.83065 12.1 3.33359 12.1C2.83654 12.1 2.43359 12.5029 2.43359 13V15.201C2.43359 16.3882 3.25531 17.4544 4.46204 17.6856C8.27225 18.4159 11.7283 18.4159 15.5385 17.6856C16.7452 17.4544 17.5669 16.3882 17.5669 15.201V13Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function DownloadPDFIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.66666 4.00007L11.8682 4C12.1246 4 12.3711 4.09844 12.557 4.27499L14.6887 6.30013C14.8875 6.48892 15 6.75102 15 7.02513V15.1821C15 15.503 14.7844 15.742 14.5274 15.7899C11.5041 16.3531 8.49597 16.3531 5.4726 15.7899C5.21562 15.742 5.00003 15.503 5.00003 15.1821L5 4.66674C5 4.29855 5.29847 4.00007 5.66666 4.00007ZM11.8682 2L5.66664 2.00007C4.19389 2.00009 3 3.19399 3 4.66674L3.00003 15.1821C3.00003 16.4062 3.84448 17.521 5.10633 17.756C8.37179 18.3643 11.6282 18.3643 14.8937 17.756C16.1555 17.521 17 16.4062 17 15.1821V7.02513C17 6.20279 16.6624 5.41649 16.0662 4.85011L13.9344 2.82497C13.3769 2.29531 12.6372 1.99999 11.8682 2ZM8.99997 10V7C8.99997 6.44772 9.44768 6 9.99997 6C10.5523 6 11 6.44771 11 7V10H12C12.4044 10 12.7691 10.2436 12.9238 10.6173C13.0786 10.991 12.9931 11.4211 12.7071 11.7071L10.7071 13.7071C10.6112 13.803 10.5007 13.8753 10.3828 13.9241C10.2648 13.973 10.1356 14 9.99997 14C9.86438 14 9.73509 13.973 9.61718 13.9241C9.50011 13.8757 9.39037 13.804 9.29499 13.7092L8.99997 10ZM8.99997 10L9.29233 13.7066L7.29286 11.7071C7.00686 11.4211 6.92131 10.991 7.07609 10.6173C7.23087 10.2436 7.59551 10 7.99997 10H8.99997Z"
        fill="currentColor"
      />
    </svg>
  );
}

interface DownloadMP3IconProps extends React.SVGProps<SVGSVGElement> {
  arrowIconClassname?: string;
}

export function DownloadMP3Icon(props: DownloadMP3IconProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <g clipPath="url(#clip0_19788_10626)">
        <path
          d="M5.85579 0C6.31603 0 6.68913 0.373096 6.68913 0.833333V11.6667C6.68913 12.1269 6.31603 12.5 5.85579 12.5C5.39556 12.5 5.02246 12.1269 5.02246 11.6667V0.833333C5.02246 0.373096 5.39556 0 5.85579 0Z"
          fill="currentColor"
        />
        <path
          d="M10.8558 1.66667C10.3956 1.66667 10.0225 2.03976 10.0225 2.5V6.25C10.0225 6.71024 10.3956 7.08333 10.8558 7.08333C11.316 7.08333 11.6891 6.71024 11.6891 6.25V2.5C11.6891 2.03976 11.316 1.66667 10.8558 1.66667Z"
          fill="currentColor"
        />
        <path
          d="M1.68913 2.5C1.68913 2.03976 1.31603 1.66667 0.855794 1.66667C0.395557 1.66667 0.0224609 2.03976 0.0224609 2.5V9.16667C0.0224609 9.6269 0.395557 10 0.855795 10C1.31603 10 1.68913 9.6269 1.68913 9.16667L1.68913 2.5Z"
          fill="currentColor"
        />
        <path
          d="M3.35579 3.33333C2.89556 3.33333 2.52246 3.70643 2.52246 4.16667L2.52246 7.5C2.52246 7.96024 2.89556 8.33333 3.35579 8.33333C3.81603 8.33333 4.18913 7.96024 4.18913 7.5L4.18913 4.16667C4.18913 3.70643 3.81603 3.33333 3.35579 3.33333Z"
          fill="currentColor"
        />
        <path
          d="M9.18913 4.16667C9.18913 3.70643 8.81603 3.33333 8.35579 3.33333C7.89556 3.33333 7.52246 3.70643 7.52246 4.16667V7.5C7.52246 7.96024 7.89556 8.33333 8.35579 8.33333C8.81603 8.33333 9.18913 7.96024 9.18913 7.5V4.16667Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.0004 14C20.0004 17.3137 17.3141 20 14.0004 20C10.6867 20 8.00043 17.3137 8.00043 14C8.00043 10.6863 10.6867 8 14.0004 8C17.3141 8 20.0004 10.6863 20.0004 14ZM14.6672 11.5485C14.6672 11.1803 14.3687 10.8818 14.0005 10.8818C13.6323 10.8818 13.3338 11.1803 13.3338 11.5485V15.3664L12.3102 14.0539C12.0498 13.7935 11.6277 13.7935 11.3674 14.0539C11.107 14.3142 11.107 14.7364 11.3674 14.9967L13.529 17.1584C13.7894 17.4187 14.2115 17.4187 14.4719 17.1584L16.6335 14.9967C16.8939 14.7364 16.8939 14.3142 16.6335 14.0539C16.3732 13.7935 15.9511 13.7935 15.6907 14.0539L14.6672 15.3663L14.6672 11.5485Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_19788_10626">
          <rect width="20" height="20" fill="none" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function DeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3.00002V1.66669C11 1.1144 10.5523 0.666687 10 0.666687C9.44771 0.666687 9 1.1144 9 1.66669V3.00002H3.5C2.94772 3.00002 2.5 3.44774 2.5 4.00002C2.5 4.5523 2.94772 5.00002 3.5 5.00002H4.05344L4.61193 15.1646C4.69932 16.7551 6.01451 18 7.60741 18H12.3926C13.9855 18 15.3007 16.7551 15.3881 15.1646L15.9466 5.00002H16.5C17.0523 5.00002 17.5 4.5523 17.5 4.00002C17.5 3.44774 17.0523 3.00002 16.5 3.00002H11ZM6.05645 5.00002L6.60892 15.0549C6.63805 15.5851 7.07644 16 7.60741 16H12.3926C12.9236 16 13.362 15.5851 13.3911 15.0549L13.9435 5.00002H6.05645Z"
        fill="currentColor"
      />
    </svg>
  );
}
