import React from 'react';

export function MoveFolderIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 17C2.89543 17 2 16.1046 2 15V4C2 2.89543 2.89543 2 4 2H7.30465C7.94297 2 8.54295 2.30469 8.91953 2.8201L9.77866 3.99596L16 3.99596C17.1046 3.99596 18 4.89139 18 5.99596V10C18 10.5523 17.5523 11 17 11C16.4477 11 16 10.5523 16 10L16 5.99596L9.27082 5.99596C8.95166 5.99596 8.65167 5.84361 8.46338 5.58591L7.30465 4L4 4L4 15H9C9.55229 15 10 15.4477 10 16C10 16.5523 9.55228 17 9 17H4ZM15 17V18C15 18.4045 15.2436 18.7691 15.6173 18.9239C15.991 19.0787 16.4211 18.9931 16.7071 18.7071L18.7071 16.7071C19.0976 16.3166 19.0976 15.6834 18.7071 15.2929L16.7071 13.2929C16.4211 13.0069 15.991 12.9213 15.6173 13.0761C15.2436 13.2309 15 13.5955 15 14V15H12C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477 17 12 17H15Z"
        fill="currentColor"
      />
    </svg>
  );
}
