import { logSegmentEvent } from 'utils/analytics';

import { getOptionalStringEnv, getRequiredStringEnv } from '../../utils/safeEnvParsing';
import { AnalyticsEventKey, AnalyticsEventProperty } from './analyticsTypes';

export const logAnalyticsEvent = <Key extends AnalyticsEventKey>(analyticsKey: Key, properties?: AnalyticsEventProperty<Key>) => {
  if (getRequiredStringEnv('NODE_ENV') === 'development') {
    console.debug('Logging analytics event:', analyticsKey, properties);
  }
  if (getOptionalStringEnv('NEXT_PUBLIC_IS_STORYBOOK') === 'true') {
    return;
  }
  return logSegmentEvent(analyticsKey, properties);
};

export { AnalyticsEventKey };
