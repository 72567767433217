/**
 * This file contains the definition/mappings for the feature config (flag, A/B test, etc.) that are used in the codebase
 * for @speechifyinc/lib-feature-flag integration
 */

import { createFeatureDefinitions, createFeatureFlag, createMultiVariantsTestFeature } from '@speechifyinc/lib-feature-flag';

export enum FeatureNameEnum {
  AI_STORY_FLOW = 'aiStoryFlow',
  EXTEND_TRIAL_FOR_CANCELLING_USERS = 'extendTrialForCancellingUsers',
  FARO_CLOUD = 'faroCloudWebapp',
  GAMIFICATION = 'webAppGamification',
  GAMIFICATION_SET_GOAL_PROMPT = 'gamificationSetGoalPrompt',
  IS_DOWNLOAD_MP3_ENABLED = 'isdownloadmp3enabled',
  OFFER_MONTHLY_OPTION_FOR_CANCELLATION_FLOW = 'offerMonthlyOptionForCancellationFlow',
  PLATFORM_SDK_SUBSCRIPTIONS = 'platformSdkSubscriptions',
  REPLACE_END_SHARE = 'replaceendshare',
  REQUEST_A_FEATURE_WEB_APP = 'requestAFeatureWebApp',
  SAASQUATCH_REFERRAL = 'saasquatchReferral',
  SDK_SLIDING_WINDOW = 'webSdkSlidingWindow',
  SHOW_EXTENSION_UPSELL_ON_WEB_LINK = 'showExtensionUpsellOnWebLink',
  SKIP_HEADER_FOOTER_UPSELL = 'webSkipHeaderFooterUpsell',
  SVL_HOVER_WEB_APP_ITEM = 'svlHoverWebAppItem',
  SVL_SHARE_FROM_WEB_DOC_END = 'svlShareFromWebDocEnd',
  USER_ACCOUNT_REDESIGN = 'userAccountRedesign',
  VOICE_CLONING = 'voiceCloningWeb',
  WA_BLACK_FRIDAY_22 = 'WABlackFriday22',
  WEB_APP_LIBRARY_OVERHAUL = 'webAppLibraryOverhaul',
  WEB_APP_ZERO_STATE = 'webAppZeroState',
  WEB_SHOW_PAGINATION = 'webShowPagination',
  WEB_SUBSCRIPTION_CANCEL_FLOW_V3 = 'webSubscriptionCancelFlowV3',
  WEB_SPEED_RAMP_ENABLED_BY_DEFAULT = 'webSpeedRampEnabledByDefault',
  WEB_APP_CLASSIC_READER_API = 'webAppClassicReaderApi',
  WEB_APP_BOOK_READER_API = 'webAppBookReaderApi',
  WEB_APP_OBSERVABILITY_SAMPLE_RATE = 'webAppObservabilitySampleRate'
}

export enum GamificationVariantEnum {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export enum IsDownloadMp3EnabledVariant {
  CONTROL = 'control',
  ENABLED_V2 = 'enabled_v2'
}

export enum SkipHeaderFooterUpsellTestVariant {
  CONTROL = 'control',
  SHOW = 'show',
  EXCLUDED = 'excluded'
}

export enum VoiceCloningTestVariant {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export enum PdfUpsellTestVariant {
  CONTROL = 'control',
  UPSELL = 'upsell',
  UPSELL_THREE = 'upsellThree'
}

export enum SpeedRampEnabledByDefaultVariant {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export enum FaroCloudVariant {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export enum WebAppLibraryOverhaulVariant {
  CONTROL = 'control',
  ENABLED = 'enabled'
}

export const ALL_FEATURE_DEFINITIONS = createFeatureDefinitions({
  [FeatureNameEnum.GAMIFICATION]: createMultiVariantsTestFeature().withVariants(GamificationVariantEnum.CONTROL, GamificationVariantEnum.ENABLED).build(),
  [FeatureNameEnum.FARO_CLOUD]: createMultiVariantsTestFeature().withVariants(FaroCloudVariant.CONTROL, FaroCloudVariant.ENABLED).build(),
  [FeatureNameEnum.SKIP_HEADER_FOOTER_UPSELL]: createMultiVariantsTestFeature()
    .withVariants(SkipHeaderFooterUpsellTestVariant.CONTROL, SkipHeaderFooterUpsellTestVariant.SHOW)
    .withCustomVariants(SkipHeaderFooterUpsellTestVariant.EXCLUDED)
    .build(),
  [FeatureNameEnum.SDK_SLIDING_WINDOW]: createFeatureFlag(),
  [FeatureNameEnum.IS_DOWNLOAD_MP3_ENABLED]: createMultiVariantsTestFeature()
    .withVariants(IsDownloadMp3EnabledVariant.CONTROL, IsDownloadMp3EnabledVariant.ENABLED_V2)
    .build(),
  [FeatureNameEnum.VOICE_CLONING]: createMultiVariantsTestFeature().withVariants(VoiceCloningTestVariant.CONTROL, VoiceCloningTestVariant.ENABLED).build(),
  [FeatureNameEnum.AI_STORY_FLOW]: createFeatureFlag(),
  [FeatureNameEnum.USER_ACCOUNT_REDESIGN]: createFeatureFlag(),
  [FeatureNameEnum.WEB_APP_LIBRARY_OVERHAUL]: createMultiVariantsTestFeature()
    .withVariants(WebAppLibraryOverhaulVariant.CONTROL, WebAppLibraryOverhaulVariant.ENABLED)
    .build(),
  [FeatureNameEnum.WEB_SPEED_RAMP_ENABLED_BY_DEFAULT]: createMultiVariantsTestFeature()
    .withVariants(SpeedRampEnabledByDefaultVariant.CONTROL, SpeedRampEnabledByDefaultVariant.ENABLED)
    .build(),
  [FeatureNameEnum.WEB_APP_CLASSIC_READER_API]: createFeatureFlag(),
  [FeatureNameEnum.WEB_APP_BOOK_READER_API]: createFeatureFlag(),
  [FeatureNameEnum.WEB_APP_OBSERVABILITY_SAMPLE_RATE]: createFeatureFlag(),
  [FeatureNameEnum.PLATFORM_SDK_SUBSCRIPTIONS]: createFeatureFlag()
} as const);
