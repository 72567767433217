import Head from 'next/head';
import { FC } from 'react';

import { APP_STORE_ID } from './config';
import { DownloadMobileAppButton } from './DownloadMobileAppButton';
import { DownloadSpeechifyIllustration } from './DownloadSpeechifyIllustration';
import { useIsFullScreenPromptShown } from './mobileAppPromptsStore';

export const FullScreenPromptProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isShown, dismiss] = useIsFullScreenPromptShown();

  return (
    <div className="relative flex flex-col flex-grow overflow-hidden">
      {children}
      {isShown && (
        <div className="fixed top-0 left-0 bottom-0 right-0 z-50 bg-white flex flex-col items-center justify-center gap-8">
          <Head>
            <meta name="apple-itunes-app" content={`app-id=${APP_STORE_ID}`}></meta>
          </Head>
          <DownloadSpeechifyIllustration />
          <div className="flex flex-col justify-center gap-6 px-10">
            <div className="flex flex-col items-center justify-center gap-2 text-center">
              <span className="text-heading-3">Get the Speechify App</span>
              <span className="text-body-6">Enjoy listening to your files on your phone. Import from anywhere, AI summarization, and more.</span>
            </div>
            <div className="flex flex-col justify-center gap-2">
              <DownloadMobileAppButton darkMode={false} />
              <button onClick={() => dismiss()} className="text-gray-500 text-subheading-6 bg-transparent border-none cursor-pointer px-spl-9 py-spl-5">
                Continue in the browser
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
