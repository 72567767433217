export const RestoreIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.4238 6.41638C14.2593 4.65754 12.2641 3.50012 10 3.50012C6.41015 3.50012 3.5 6.41027 3.5 10.0001C3.5 13.59 6.41015 16.5001 10 16.5001C13.3367 16.5001 16.0874 13.985 16.4576 10.7478C16.5203 10.1991 17.016 9.80514 17.5647 9.86787C18.1134 9.93061 18.5073 10.4263 18.4446 10.975C17.9603 15.2107 14.365 18.5001 10 18.5001C5.30558 18.5001 1.5 14.6945 1.5 10.0001C1.5 5.3057 5.30558 1.50012 10 1.50012C12.7528 1.50012 15.1988 2.80891 16.7514 4.83545V3.54064C16.7514 2.98836 17.1991 2.54064 17.7514 2.54064C18.3037 2.54064 18.7514 2.98836 18.7514 3.54064V7.41638C18.7514 7.96867 18.3037 8.41638 17.7514 8.41638L13.8756 8.41638C13.3233 8.41638 12.8756 7.96867 12.8756 7.41638C12.8756 6.8641 13.3233 6.41638 13.8756 6.41638L15.4238 6.41638Z"
      fill="currentColor"
    />
  </svg>
);

export const DeleteForeverIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 2.99996V1.66663C11 1.11434 10.5523 0.666626 10 0.666626C9.44771 0.666626 9 1.11434 9 1.66663V2.99996H3.5C2.94772 2.99996 2.5 3.44767 2.5 3.99996C2.5 4.55224 2.94772 4.99996 3.5 4.99996H4.05344L4.61193 15.1645C4.69932 16.7551 6.01451 18 7.60741 18H12.3926C13.9855 18 15.3007 16.7551 15.3881 15.1645L15.9466 4.99996H16.5C17.0523 4.99996 17.5 4.55224 17.5 3.99996C17.5 3.44767 17.0523 2.99996 16.5 2.99996H11ZM6.05645 4.99996L6.60892 15.0548C6.63805 15.585 7.07644 16 7.60741 16H12.3926C12.9236 16 13.362 15.585 13.3911 15.0548L13.9435 4.99996H6.05645Z"
      className="fill-icn-txt-crit"
    />
  </svg>
);

export const TrashWithXIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
    <path
      d="M10.8326 9.05983C10.4812 8.70836 9.9113 8.70836 9.55983 9.05983C9.20836 9.4113 9.20836 9.98115 9.55983 10.3326L11.2272 12L9.55983 13.6674C9.20836 14.0188 9.20836 14.5887 9.55983 14.9402C9.9113 15.2916 10.4812 15.2916 10.8326 14.9402L12.5 13.2728L14.1674 14.9402C14.5189 15.2917 15.0887 15.2917 15.4402 14.9402C15.7917 14.5887 15.7917 14.0189 15.4402 13.6674L13.7728 12L15.4402 10.3326C15.7917 9.9811 15.7917 9.41125 15.4402 9.05978C15.0887 8.70831 14.5189 8.70831 14.1674 9.05978L12.5 10.7272L10.8326 9.05983Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5 3V2C13.5 1.44772 13.0523 1 12.5 1C11.9477 1 11.5 1.44772 11.5 2V3H4.37085C3.81856 3 3.37085 3.44772 3.37085 4C3.37085 4.55228 3.81856 5 4.37085 5H4.52898L4.93192 18.7C4.98598 20.538 6.49161 22 8.33045 22H16.6696C18.5084 22 20.014 20.538 20.0681 18.7L20.4712 4.99503C20.9763 4.94471 21.3708 4.51843 21.3708 4C21.3708 3.44772 20.9231 3 20.3708 3H13.5ZM6.52984 5L6.93105 18.6412C6.95331 19.398 7.57328 20 8.33045 20H16.6696C17.4267 20 18.0467 19.398 18.0689 18.6412L18.4702 5H6.52984Z"
      fill="currentColor"
    />
  </svg>
);
