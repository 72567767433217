import { getRequiredStringEnv } from '../../utils/safeEnvParsing';

export const firebaseConfig = {
  apiKey: getRequiredStringEnv('NEXT_PUBLIC_FB_API_KEY'),
  authDomain: getRequiredStringEnv('NEXT_PUBLIC_FB_AUTH_DOMAIN'),
  databaseURL: getRequiredStringEnv('NEXT_PUBLIC_FB_DATABASE_URL'),
  projectId: getRequiredStringEnv('NEXT_PUBLIC_FB_PROJECT_ID'),
  storageBucket: getRequiredStringEnv('NEXT_PUBLIC_FB_STORAGE_BUCKET'),
  messagingSenderId: getRequiredStringEnv('NEXT_PUBLIC_FB_MESSAGING_SENDER_ID'),
  appId: getRequiredStringEnv('NEXT_PUBLIC_FB_APP_ID'),
  dynamicLinkDomain: getRequiredStringEnv('NEXT_PUBLIC_FB_DYNAMIC_LINK_DOMAIN'),
  iosBundleId: getRequiredStringEnv('NEXT_PUBLIC_FB_IOS_BUNDLE_ID'),
  iosAppstoreId: getRequiredStringEnv('NEXT_PUBLIC_FB_IOS_APPSTORE_ID')
};
