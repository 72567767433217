import * as configcat from 'configcat-js';

import { FeatureNameEnum } from 'config/constants/featureDefinitions';
import { getOptionalStringEnv } from 'utils/safeEnvParsing';

import pkg from '../../../package.json';

// This function is due to circular dependencies in src\hooks\useFeatureFlags.ts - it requires faro for logging
export const isGrafanaCloudFeatureFlagEnabled = async () => {
  const configCatApiKey = getOptionalStringEnv('NEXT_PUBLIC_CONFIG_CAT_API_KEY');
  if (!configCatApiKey) return false;

  const configCatClient = configcat.getClient(configCatApiKey);

  const settings = await configCatClient.getAllValuesAsync({
    custom: {
      chromeExtensionVersion: '0.0.0',
      webAppVersion: pkg.version
    },
    identifier: 'incognito',
    email: 'incognito@example.com'
  });

  const grafanaCloudFeatureFlag = settings.find(({ settingKey }) => settingKey === FeatureNameEnum.FARO_CLOUD);

  return grafanaCloudFeatureFlag?.settingValue === true;
};
