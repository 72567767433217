import { IToast } from 'interfaces';
import Head from 'next/head';
import React, { Fragment } from 'react';

import Toast from 'components/elements/Toast';
import useToastStore from 'modules/toast/stores/toastStore';

export const MainWrapper = ({ children }: { children: React.ReactNode }) => {
  const toasts = useToastStore(state => state.toasts);

  return (
    <Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <div aria-live="assertive" className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6" style={{ zIndex: 9999 }}>
        <div className="flex w-full flex-col items-center space-y-4">
          {toasts.map((toast: IToast, i: number) => (
            <Toast toast={toast} key={i} />
          ))}
        </div>
      </div>

      {children}
    </Fragment>
  );
};

export default MainWrapper;
