import styled from '@emotion/styled';
import React from 'react';

export function Spinner(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="28" height="28" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="22" cy="22" r="16" fill="none" stroke="currentColor" strokeWidth="6" className="stroke-bg-blue" />
      <g>
        <circle cx="22" cy="22" r="16" fill="none" strokeWidth="6" className="stroke-icn-txt-blue" />
      </g>
    </svg>
  );
}

export const LoadingSpinner = styled(Spinner)<{ top?: string; left?: string }>`
  pointer-events: none;
  g {
    transform-origin: center;
    animation: spinner_rotation 2s linear infinite;
  }

  g circle {
    stroke-linecap: round;
    animation: spinner_dash 2s ease-in-out infinite;
  }

  @keyframes spinner_rotation {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes spinner_dash {
    0% {
      stroke-dasharray: 0 430;
      stroke-dashoffset: 0;
    }

    47.5% {
      stroke-dasharray: 92 430;
      stroke-dashoffset: -35;
    }

    95%,
    100% {
      stroke-dasharray: 92 430;
      stroke-dashoffset: -119;
    }
  }
`;
