/* eslint n/no-process-env: 0 */

/**
 * Storybook does not provide process.env object. It only full text replaces `process.env.VAR_NAME`. So we need to specify here all the env vars that we might want to use and only then we can dynamically load our own object.
 * If adding a new variable we need to also add it into this list. It's okay that this list will be loaded even on client as on client the values for non-public variables will not be populated.
 */
const allEnvVars = {
  AMP_ANDROID: process.env.AMP_ANDROID,
  AMP_ANDROID_SECRET: process.env.AMP_ANDROID_SECRET,
  AMP_API_KEY: process.env.AMP_API_KEY,
  AMP_IOS: process.env.AMP_IOS,
  AMP_IOS_SECRET: process.env.AMP_IOS_SECRET,
  AMP_SECRET_KEY: process.env.AMP_SECRET_KEY,
  FB_CLIENT_EMAIL: process.env.FB_CLIENT_EMAIL,
  FIREBASE_PRIVATE_KEY: process.env.FIREBASE_PRIVATE_KEY,
  GCP_VISION_KEY: process.env.GCP_VISION_KEY,
  IS_DEV: process.env.IS_DEV,
  JWT_TOKEN_WEB: process.env.JWT_TOKEN_WEB,
  KV_REST_API_READ_ONLY_TOKEN: process.env.KV_REST_API_READ_ONLY_TOKEN,
  KV_REST_API_TOKEN: process.env.KV_REST_API_TOKEN,
  KV_REST_API_URL: process.env.KV_REST_API_URL,
  KV_URL: process.env.KV_URL,
  NEXT_PUBLIC_AI_CHAT_URL: process.env.NEXT_PUBLIC_AI_CHAT_URL,
  NEXT_PUBLIC_AMPLITUDE_APIKEY: process.env.NEXT_PUBLIC_AMPLITUDE_APIKEY,
  NEXT_PUBLIC_AUDIO_SERVER_URL: process.env.NEXT_PUBLIC_AUDIO_SERVER_URL,
  NEXT_PUBLIC_AUTH_SERVER: process.env.NEXT_PUBLIC_AUTH_SERVER,
  NEXT_PUBLIC_CDN_BASEURL: process.env.NEXT_PUBLIC_CDN_BASEURL,
  NEXT_PUBLIC_CHROME_REMOTE_CONFIG_URL: process.env.NEXT_PUBLIC_CHROME_REMOTE_CONFIG_URL,
  NEXT_PUBLIC_CONFIG_CAT_API_KEY: process.env.NEXT_PUBLIC_CONFIG_CAT_API_KEY,
  NEXT_PUBLIC_CORS_PROXY_URL: process.env.NEXT_PUBLIC_CORS_PROXY_URL,
  NEXT_PUBLIC_DEBUGGING_TOOLS_ENABLED: process.env.NEXT_PUBLIC_DEBUGGING_TOOLS_ENABLED,
  NEXT_PUBLIC_DEV_DISABLE_NOISY_CONSOLE_LOG: process.env.NEXT_PUBLIC_DEV_DISABLE_NOISY_CONSOLE_LOG,
  NEXT_PUBLIC_DIAGNOSTICS_SERVICE_URL: process.env.NEXT_PUBLIC_DIAGNOSTICS_SERVICE_URL,
  NEXT_PUBLIC_DL_KEY: process.env.NEXT_PUBLIC_DL_KEY,
  NEXT_PUBLIC_DRIP_ACCOUNT_ID: process.env.NEXT_PUBLIC_DRIP_ACCOUNT_ID,
  NEXT_PUBLIC_EBOOK_BACKEND_URL: process.env.NEXT_PUBLIC_EBOOK_BACKEND_URL,
  NEXT_PUBLIC_ENTITLEMENTS_URL: process.env.NEXT_PUBLIC_ENTITLEMENTS_URL,
  NEXT_PUBLIC_EXPORT_SEVICE_URL: process.env.NEXT_PUBLIC_EXPORT_SEVICE_URL,
  NEXT_PUBLIC_FARO_CLOUD_COLLECTOR_URL: process.env.NEXT_PUBLIC_FARO_CLOUD_COLLECTOR_URL,
  NEXT_PUBLIC_FARO_COLLECTOR_URL: process.env.NEXT_PUBLIC_FARO_COLLECTOR_URL,
  NEXT_PUBLIC_FB_API_KEY: process.env.NEXT_PUBLIC_FB_API_KEY,
  NEXT_PUBLIC_FB_APP_ID: process.env.NEXT_PUBLIC_FB_APP_ID,
  NEXT_PUBLIC_FB_AUTH_DOMAIN: process.env.NEXT_PUBLIC_FB_AUTH_DOMAIN,
  NEXT_PUBLIC_FB_DATABASE_URL: process.env.NEXT_PUBLIC_FB_DATABASE_URL,
  NEXT_PUBLIC_FB_DYNAMIC_LINK_DOMAIN: process.env.NEXT_PUBLIC_FB_DYNAMIC_LINK_DOMAIN,
  NEXT_PUBLIC_FB_IOS_APPSTORE_ID: process.env.NEXT_PUBLIC_FB_IOS_APPSTORE_ID,
  NEXT_PUBLIC_FB_IOS_BUNDLE_ID: process.env.NEXT_PUBLIC_FB_IOS_BUNDLE_ID,
  NEXT_PUBLIC_FB_MESSAGING_SENDER_ID: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
  NEXT_PUBLIC_FB_PROJECT_ID: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
  NEXT_PUBLIC_FB_SHORTURL_DYNAMIC_LINK_DOMAIN: process.env.NEXT_PUBLIC_FB_SHORTURL_DYNAMIC_LINK_DOMAIN,
  NEXT_PUBLIC_FB_STORAGE_BUCKET: process.env.NEXT_PUBLIC_FB_STORAGE_BUCKET,
  NEXT_PUBLIC_FOYER_URL: process.env.NEXT_PUBLIC_FOYER_URL,
  NEXT_PUBLIC_INTEGRATIONS_BASEURL: process.env.NEXT_PUBLIC_INTEGRATIONS_BASEURL,
  NEXT_PUBLIC_INTERCOM_APPID: process.env.NEXT_PUBLIC_INTERCOM_APPID,
  NEXT_PUBLIC_IS_DEV: process.env.NEXT_PUBLIC_IS_DEV,
  NEXT_PUBLIC_IS_STORYBOOK: process.env.NEXT_PUBLIC_IS_STORYBOOK,
  NEXT_PUBLIC_LLM_API_URL: process.env.NEXT_PUBLIC_LLM_API_URL,
  NEXT_PUBLIC_OCR_API_URL: process.env.NEXT_PUBLIC_OCR_API_URL,
  NEXT_PUBLIC_ONBOARDING_URL: process.env.NEXT_PUBLIC_ONBOARDING_URL,
  NEXT_PUBLIC_PAYMENTS_URL: process.env.NEXT_PUBLIC_PAYMENTS_URL,
  NEXT_PUBLIC_PAYMENT_API_URL: process.env.NEXT_PUBLIC_PAYMENT_API_URL,
  NEXT_PUBLIC_PAYMENT_SERVER_URL: process.env.NEXT_PUBLIC_PAYMENT_SERVER_URL,
  NEXT_PUBLIC_PLATFORM_CATALOG_URL: process.env.NEXT_PUBLIC_PLATFORM_CATALOG_URL,
  NEXT_PUBLIC_PLATFORM_ML_PAGE_PARSING_URL: process.env.NEXT_PUBLIC_PLATFORM_ML_PAGE_PARSING_URL,
  NEXT_PUBLIC_PSPKIT: process.env.NEXT_PUBLIC_PSPKIT,
  NEXT_PUBLIC_PSPKIT_DEV_PREVIEW: process.env.NEXT_PUBLIC_PSPKIT_DEV_PREVIEW,
  NEXT_PUBLIC_PSPKIT_LOCALHOST: process.env.NEXT_PUBLIC_PSPKIT_LOCALHOST,
  NEXT_PUBLIC_SAASQUATCH_PROJECT_ID: process.env.NEXT_PUBLIC_SAASQUATCH_PROJECT_ID,
  NEXT_PUBLIC_SAASQUATCH_TENANT_ID: process.env.NEXT_PUBLIC_SAASQUATCH_TENANT_ID,
  NEXT_PUBLIC_SAME_DOMAIN_ONBOARDING_URL: process.env.NEXT_PUBLIC_SAME_DOMAIN_ONBOARDING_URL,
  NEXT_PUBLIC_SEGMENT_PROXY_API_HOST: process.env.NEXT_PUBLIC_SEGMENT_PROXY_API_HOST,
  NEXT_PUBLIC_SEGMENT_PROXY_CDN_HOST: process.env.NEXT_PUBLIC_SEGMENT_PROXY_CDN_HOST,
  NEXT_PUBLIC_SEGMENT_WRITEKEY: process.env.NEXT_PUBLIC_SEGMENT_WRITEKEY,
  NEXT_PUBLIC_SHORTURL_DL_KEY: process.env.NEXT_PUBLIC_SHORTURL_DL_KEY,
  NEXT_PUBLIC_STRIPE_MONTHLY_29_PLAN_ID: process.env.NEXT_PUBLIC_STRIPE_MONTHLY_29_PLAN_ID,
  NEXT_PUBLIC_TI_TEMPLATE_ID: process.env.NEXT_PUBLIC_TI_TEMPLATE_ID,
  NEXT_PUBLIC_TRANSLOADIT_KEY: process.env.NEXT_PUBLIC_TRANSLOADIT_KEY,
  NEXT_PUBLIC_TRANSLOADIT_SECRET: process.env.NEXT_PUBLIC_TRANSLOADIT_SECRET,
  NEXT_PUBLIC_TRANSLOADIT_TEMPLATE: process.env.NEXT_PUBLIC_TRANSLOADIT_TEMPLATE,
  NEXT_PUBLIC_VERBOSE_MEASUREMENT: process.env.NEXT_PUBLIC_VERBOSE_MEASUREMENT,
  NEXT_PUBLIC_VOICEOVER_BACKEND_URL: process.env.NEXT_PUBLIC_VOICEOVER_BACKEND_URL,
  NEXT_PUBLIC_VOICES_SERVER: process.env.NEXT_PUBLIC_VOICES_SERVER,
  NEXT_PUBLIC_WEBAPP_URL: process.env.NEXT_PUBLIC_WEBAPP_URL,
  NEXT_PUBLIC_USER_PROFILE_API_URL: process.env.NEXT_PUBLIC_USER_PROFILE_API_URL,
  NODE_ENV: process.env.NODE_ENV,
  NPM_TOKEN: process.env.NPM_TOKEN,
  OPENAI: process.env.OPENAI,
  REDIS_DATABASE_ENDPOINT: process.env.REDIS_DATABASE_ENDPOINT,
  SAASQUATCH_API_KEY: process.env.SAASQUATCH_API_KEY,
  SPEECHIFY_ENV: process.env.SPEECHIFY_ENV,
  TEAMS_API_BASEURL: process.env.TEAMS_API_BASEURL,
  VERCEL_ENV: process.env.VERCEL_ENV,
  version: process.env.version
};
type EnvVarName = keyof typeof allEnvVars;

const getRawEnv = (key: EnvVarName) => process.env[key] ?? allEnvVars[key];

export const getOptionalStringEnv = (key: EnvVarName) => {
  const value = getRawEnv(key);
  if (value === '' || value === undefined) return undefined;
  return value;
};

export const getRequiredStringEnv = (key: EnvVarName) => {
  const value = getOptionalStringEnv(key);
  if (value === undefined) {
    const errorMessage = `Missing required string env variable '${key}'`;
    // Let's warn in the console if there is missing env even if the error is caught
    console.warn(errorMessage);
    throw new Error(errorMessage);
  }
  return value;
};

export const getStringEnvWithDefault = (key: EnvVarName, defaultValue: string) => getOptionalStringEnv(key) ?? defaultValue;
