import { TransportItemType, WebVitalsInstrumentation, getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk';
import * as ct from 'countries-and-timezones';

import { FeatureNameEnum, getFeatureVariant } from 'hooks/useFeatureFlags';
import { getRequiredStringEnv } from 'utils/safeEnvParsing';

import { ConsoleInstrumentation } from './ConsoleInstrumentation';

type InitParams = {
  isolate?: boolean;
  isGrafanaSpeechifyHosted?: boolean;
};

export enum FaroEventNameEnum {
  UNIQUE_VISIT = 'unique_visit',
  TOTAL_VISIT = 'total_visit',
  DEVICE_SPECS = 'device_specs'
}

export const BYPASS_SAMPLE_RATE_EVENTS = [FaroEventNameEnum.DEVICE_SPECS];

export const DUMMY_SESSION_ID = 'anonymous';
const LOCAL_STORAGE_USER_SAMPLE_KEY = 'user_sample_value';

export const getSampleRate = async () => {
  const fallbackSampleRate = 0.2;
  const sampleRateVariant = await getFeatureVariant(FeatureNameEnum.WEB_APP_OBSERVABILITY_SAMPLE_RATE);

  return Number(sampleRateVariant) ?? fallbackSampleRate;
};

const getInstrumentations = (isGrafanaSpeechifyHosted: boolean) => {
  const webInstrumentations = getWebInstrumentations({
    captureConsole: false,
    enablePerformanceInstrumentation: isGrafanaSpeechifyHosted
  });

  return isGrafanaSpeechifyHosted ? webInstrumentations : webInstrumentations.filter(instr => !(instr instanceof WebVitalsInstrumentation)); // Explicitly remove Web Vitals
};

const getUserSamplingDecision = (sampleRate: number): boolean => {
  let userSampleValue = localStorage.getItem(LOCAL_STORAGE_USER_SAMPLE_KEY);

  if (!userSampleValue) {
    userSampleValue = Math.random().toString();
    localStorage.setItem(LOCAL_STORAGE_USER_SAMPLE_KEY, userSampleValue);
  }

  return parseFloat(userSampleValue) <= sampleRate;
};

export const initFaro = async (collectorUrl: string, { isGrafanaSpeechifyHosted = false, isolate = false }: InitParams) => {
  try {
    const sampleRate = await getSampleRate();
    const shouldSampleUser = getUserSamplingDecision(sampleRate);

    const instrumentations = getInstrumentations(isGrafanaSpeechifyHosted);

    const faro = initializeFaro({
      url: collectorUrl,
      instrumentations: [...instrumentations, new ConsoleInstrumentation()],
      app: {
        name: 'Speechify Web App',
        version: getRequiredStringEnv('version')
      },
      isolate,
      beforeSend: event => {
        if (event.meta.session) {
          event.meta.session.id = DUMMY_SESSION_ID;

          if (event.meta.session.attributes) {
            event.meta.session.attributes.previousSession = DUMMY_SESSION_ID;
            event.meta.session.attributes.country = ct.getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)?.id || '--';
          }
        }
        // Temporarily disable sampling for our hosted grafana cloud instance
        if (!isGrafanaSpeechifyHosted) {
          if (
            event.type !== TransportItemType.EXCEPTION &&
            shouldSampleUser &&
            (event.type !== 'event' || !BYPASS_SAMPLE_RATE_EVENTS.includes((event.payload as { name: string }).name as FaroEventNameEnum))
          ) {
            return null;
          }
        }

        return event;
      }
    });

    faro.api.pushLog(['Faro initialized']);
    return faro;
  } catch (err) {
    console.error('Error initializing Faro:', err);
    return undefined;
  }
};
