import { getAuth } from 'firebase/auth';
import React, { ComponentType, useEffect } from 'react';

import { firebaseApp } from 'lib/firebase/firebase.client';

import * as faro from './';

const useFaro = () => {
  useEffect(() => {
    getAuth(firebaseApp).onAuthStateChanged(user => {
      if (user?.uid) {
        faro.setUser({
          id: user!.uid,
          email: user.email!
        });
      }
    });
  }, []);
};

export default function withFaro<T extends object>(ReactComponent: ComponentType<T>): ComponentType<T> {
  const WithFaroComponent = (props: T) => {
    useFaro();
    return <ReactComponent {...props} />;
  };
  return WithFaroComponent;
}
