import React from 'react';

export function AddFolderIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 17C3.39543 17 2.5 16.1046 2.5 15V4C2.5 2.89543 3.39543 2 4.5 2H7.80465C8.44297 2 9.04295 2.30469 9.41953 2.8201L10.2787 3.99596L16.5 3.99596C17.6046 3.99596 18.5 4.89139 18.5 5.99596V9C18.5 9.55229 18.0523 10 17.5 10C16.9477 10 16.5 9.55229 16.5 9L16.5 5.99596L9.77082 5.99596C9.45166 5.99596 9.15167 5.84361 8.96338 5.58591L7.80465 4L4.5 4L4.5 15H9.5C10.0523 15 10.5 15.4477 10.5 16C10.5 16.5523 10.0523 17 9.5 17H4.5ZM15.5 11C16.0523 11 16.5 11.4477 16.5 12V14H18.5C19.0523 14 19.5 14.4477 19.5 15C19.5 15.5523 19.0523 16 18.5 16H16.5V18C16.5 18.5523 16.0523 19 15.5 19C14.9477 19 14.5 18.5523 14.5 18V16H12.5C11.9477 16 11.5 15.5523 11.5 15C11.5 14.4477 11.9477 14 12.5 14H14.5V12C14.5 11.4477 14.9477 11 15.5 11Z"
        fill="currentColor"
      />
    </svg>
  );
}
