import 'client-only';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { initializeFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

import { firebaseConfig } from './constants';

const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);

const firebaseFirestore = initializeFirestore(firebaseApp, { experimentalAutoDetectLongPolling: true });

const firebaseStorage = getStorage(firebaseApp);

export { firebaseApp, firebaseAuth, firebaseFirestore, firebaseStorage };
