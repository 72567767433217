import { IToast } from 'interfaces';

import { createNonPersistentStore } from 'lib/zustand';

interface ToastStoreState {
  id: number;
  toasts: IToast[];
}

const useToastStore = createNonPersistentStore<ToastStoreState>(
  () => ({
    id: 1,
    toasts: []
  }),
  {
    isListeningScreenStore: false
  }
);

export default useToastStore;
