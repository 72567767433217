import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { AnalyticsEventKey, logAnalyticsEvent } from 'modules/analytics/logAnalyticsEvent';

const useTrackRouteVisit = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      logAnalyticsEvent(AnalyticsEventKey.routeVisited, {
        path: url
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);
};

export default useTrackRouteVisit;
