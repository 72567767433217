import React from 'react';

export function CloseIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.87361 3.45952C4.48309 3.06899 3.84992 3.06899 3.4594 3.45952C3.06887 3.85004 3.06887 4.48321 3.4594 4.87373L8.58562 9.99996L3.4594 15.1262C3.06887 15.5167 3.06887 16.1499 3.4594 16.5404C3.84992 16.9309 4.48309 16.9309 4.87361 16.5404L9.99984 11.4142L15.1261 16.5404C15.5166 16.9309 16.1498 16.9309 16.5403 16.5404C16.9308 16.1499 16.9308 15.5167 16.5403 15.1262L11.4141 9.99996L16.5403 4.87373C16.9308 4.48321 16.9308 3.85004 16.5403 3.45952C16.1498 3.06899 15.5166 3.06899 15.1261 3.45952L9.99984 8.58575L4.87361 3.45952Z"
        fill="currentColor"
      />
    </svg>
  );
}
