import React from 'react';

export function SuccessIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M15.5696 6.27905C16.0159 6.72537 16.0159 7.44899 15.5696 7.8953L9.52906 13.9356C9.08275 14.3819 8.35914 14.3819 7.91283 13.9356L4.43046 10.4532C3.98414 10.0069 3.98414 9.28325 4.43046 8.83694C4.87677 8.39062 5.60039 8.39062 6.0467 8.83694L8.72097 11.5112L13.9534 6.27902C14.3997 5.83272 15.1233 5.83273 15.5696 6.27905Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM17.7143 10C17.7143 14.2605 14.2605 17.7143 10 17.7143C5.73952 17.7143 2.28571 14.2605 2.28571 10C2.28571 5.73952 5.73952 2.28571 10 2.28571C14.2605 2.28571 17.7143 5.73952 17.7143 10Z"
        fill="currentColor"
      />
    </svg>
  );
}
