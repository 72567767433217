import React from 'react';

export function ErrorIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7094 2.29289C16.5219 2.10536 16.2675 2 16.0023 2H7.99769C7.73248 2 7.47812 2.10536 7.29059 2.29289L2.29289 7.29059C2.10536 7.47813 2 7.73248 2 7.9977V16.0023C2 16.2675 2.10536 16.5219 2.29289 16.7094L7.29059 21.7071C7.47812 21.8946 7.73248 22 7.99769 22H16.0023C16.2675 22 16.5219 21.8946 16.7094 21.7071L21.7071 16.7094C21.8946 16.5219 22 16.2675 22 16.0023V7.9977C22 7.73248 21.8946 7.47813 21.7071 7.29059L16.7094 2.29289ZM15.5881 4L20 8.41191V15.5881L15.5881 20H8.41191L4 15.5881V8.41191L8.41191 4H15.5881Z"
        fill="#FF3C30"
      />
      <path
        d="M10.7951 7.79671C10.7437 7.09654 11.298 6.5002 12 6.5002C12.7021 6.5002 13.2563 7.09654 13.2049 7.79671L12.8049 13.2522C12.7739 13.6739 12.4228 14.0002 12 14.0002C11.5772 14.0002 11.2261 13.6739 11.1952 13.2522L10.7951 7.79671Z"
        fill="#FF3C30"
      />
      <path
        d="M13.1998 16.2998C13.1998 16.9625 12.6625 17.4998 11.9998 17.4998C11.3371 17.4998 10.7998 16.9625 10.7998 16.2998C10.7998 15.6371 11.3371 15.0998 11.9998 15.0998C12.6625 15.0998 13.1998 15.6371 13.1998 16.2998Z"
        fill="#FF3C30"
      />
    </svg>
  );
}
